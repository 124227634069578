import Uno from "../../images/carrusel/1.jpeg"
import Dos from "../../images/carrusel/2.jpeg"
import Tres from "../../images/carrusel/3.jpeg"
import Cuatro from "../../images/carrusel/4.jpeg"

export default {
    Uno,
    Dos,
    Tres,
    Cuatro
}