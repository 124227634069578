
import React, {Component} from 'react'
import './Faq.css'


class Faq extends Component
{

	render()
	{
		return (
			<section className = 'faq py-5 mb-5' id = 'faq'>
			</section>
		)
	}
}

export default Faq